<template>
  <v-app class="white">
    <app-toolbar :patient="patient" />

    <v-main>
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>

    <!-- Snackbars -->
    <snackbar-message></snackbar-message>
  </v-app>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import SnackbarMessage from './shared-ui/components/SnackbarMessage.vue'
//import { onAuthChanged } from './shared-ui/hooks/auth'

import AppToolbar from './components/AppToolbar.vue'
import ExportDialog from './components/ExportDialog.vue'
//import peer from './helpers/peer'
import { getLatestData, listenForProviderReview } from '@/shared-ui/store/tasks'
import { getOrgUsers } from '@/shared-ui/store/org'
import { router } from '@/router/index.js'
import * as bridge from '@arrowhealth/bridge-sdk'

export default defineComponent({
  components: {
    AppToolbar,
    ExportDialog,
    SnackbarMessage,
  },
  setup() {
    const authUser = ref({})
    const authStatus = ref({})
    const patient = ref(null)

    //onAuthChanged(() => peer.send({ type: 'AUTH_CHANGED' }))

    const refreshPatientList = async () => {
      await getLatestData()
      window.localStorage.setItem('taskboardAge', String(new Date().getTime()))
    }

    const navigateToPatient = id => {
      router.push({
        name: 'Patient',
        params: { id: id, tab: 0 },
      })
    }

    const navigateToTaskboard = () => {
      router.push({
        name: 'Taskboard',
      })
    }
    const grabThePatient = () => {
      bridge.getPatient().then(v => {
        console.log('patient:', v)
      })
    }

    onMounted(async () => {
      // hide app badge on open
      console.log(bridge.version)
      console.log('rpm version: ', 2.1)
      bridge.getAuthUser().then(v => (authUser.value = v))
      bridge.getAuthStatus().then(v => (authStatus.value = v))
      // only navigate to the taskboard if the user exists and latest data is back?
      bridge.getPatient().then(v => {
        console.log('get patient on mounted:', v)
        patient.value = v
        if (patient.value) {
          navigateToPatient(patient.value.id)
        } else {
          navigateToTaskboard()
        }
      })
      bridge.onPatientChanged(v => {
        console.log('on patient changed:', v)
        patient.value = v
        if (patient.value) {
          navigateToPatient(patient.value.id)
        } else {
          navigateToTaskboard()
        }
      })

      await getLatestData()
      await getOrgUsers()
      await listenForProviderReview()
    })

    return { refreshPatientList, patient, grabThePatient }
  },
})
</script>
<style>
.novoSoftCardShadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01), 0 4px 4px rgba(0, 0, 0, 0.01),
    0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
}

.novoDeviceSelectCardUnselected {
  border: 2px solid #f5f5f5;
  border-radius: 6px;
}

.novoDeviceSelectCardUnselected:hover {
  border: 2px solid #d9d9d9;
  border-radius: 6px;
}

.novoDeviceSelectCardSelected {
  border: 2px solid #2296f2;
  border-radius: 6px;
}

.novoDeviceSelectCardHover {
  border: 1px solid #a0a0a0;
  border-radius: 8px;
}

.selectedDevice {
  border: 1px solid blue;
  border-radius: 8px;
}
.novoGradientBlue {
  background: #457fca; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #00233d, #002b4d); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #00233d,
    #002b4d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.novoGradientWater {
  background: #e0eafc; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #117fa6, #1599ca); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #138db9,
    #1599ca
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
