import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'taskboard',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/TaskboardPage.vue'),
  },
  {
    path: '/taskboard',
    name: 'Taskboard',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/TaskboardPage.vue'),
  },
  {
    path: '/patient/:id/:tab?',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/PatientPage.vue'),
  },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
