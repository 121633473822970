import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, readonly } from '@vue/composition-api'
import { rpmClient } from './clients/rpm'
import { patientsClient } from './clients/patients'
import camelCase from 'lodash/camelCase'
import { getOrgId } from './sso'

Vue.use(VueCompositionAPI)

const _state = reactive({
  patient: null,
  events: [],
  eventsByKind: {},
})
export const state = readonly(_state)

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getPatient = async (orgPatientId, patientId) => {
  try {
    let orgId = await getOrgId()
    const { data } = await rpmClient.post('/GetPatient', { patientId, orgId, orgPatientId })
    _state.patient = data.patient
    _state.eventsByKind = {}
    return data.patient
  } catch (error) {
    _state.patient = null
    return null
  }
}

const fixEventKind = eventKind => {
  eventKind = eventKind.replace('MEASUREMENT_', '')
  return capitalizeFirstLetter(camelCase(eventKind))
}

export const getPatientEvents = async (patientId, eventKind, startDate, endDate, pageSize = 100) => {
  eventKind = fixEventKind(eventKind)
  const start = startDate.toISOString()
  const end = endDate.toISOString()
  let { data } = await rpmClient.post(`/GetPatientEvents`, { pageSize, patientId, eventKind, start, end })
  _state.events = data.events

  _state.eventsByKind[eventKind] = data.events.map(event => {
    // if (event.data) {
    //   if (event.data.value) {
    //     event.data.value = event.data.value
    //   }
    //   if (event.data.diastolicValue) {
    //     event.data.diastolicValue = event.data.diastolicValue
    //   }
    //   if (event.data.systolicValue) {
    //     event.data.systolicValue = event.data.systolicValue
    //   }
    // }
    return event
  })
  return data.events
}

export const removePatientAlert = async patientEventId => {
  let { data } = await rpmClient.post(`/AckAlert`, { patientEventId })
  // return something that days it was successful
  return data
}

export const getPatientDevices = async (patientId, pageSize = 100) => {
  let { data } = await rpmClient.post(`/GetPatientDevices`, { patientId, pageSize })
  // return something that days it was successful
  return data.devices
}

// https://api.rpm.novorpm.com/RpmSessionStart
export const startRpmSession = async patientId => {
  let { data } = await rpmClient.post(`/RpmSessionStart`, { patientId })
  return data.sessionId
}

// https://api.rpm.novorpm.com/RpmSessionHeartbeat
export const updateRpmSession = async sessionId => {
  await rpmClient.post(`/RpmSessionHeartbeat`, { sessionId })
}

// Still in the legacy api for now [DUPLICATE IN PATIENT.js]
export const createPatient = async patient => {
  const { data } = await patientsClient.post('/CreatePatient', patient)
  _state.patient = data.patient
  return data.patient
}
// Still in the legacy api for now [DUPLICATE IN PATIENT.js]
export const assignDevice = async (patientId, kind, deviceRefId) => {
  let { data } = await patientsClient.post('/AssignDevice', {
    patientId,
    kind,
    deviceRefId,
  })
  return data.device
}

export const unassignDevice = async id => {
  let { data } = await rpmClient.post('/UnassignDevice', {
    id,
  })
  return data.device
}

export const getOrgAlertRules = async () => {
  const { data } = await rpmClient.post('/GetOrgDefaultAlertRules')
  return data.alertRules
}

export const setOrgAlertRules = async alertRules => {
  const { data } = await rpmClient.post('/SetOrgDefaultAlertRules', { alertRules: alertRules })
  return data.alertRules
}
export const addPatientNote = async (patientId, text, tags) => {
  await rpmClient.post('/AddNote', { patientId, text, tags })
  return 'Made it'
}

export const respondToNote = async (patientId, noteUserId, noteTs, text) => {
  await rpmClient.post('/AddNoteComment', { patientId, noteUserId, noteTs, text })
  return 'Made it'
}

export const getOrganizationDevices = async (pageSize, pageToken) => {
  try {
    let orgId = await getOrgId()
    if (orgId) {
      pageSize = pageSize || 250
      pageToken = pageToken || ''
      const { data } = await rpmClient.post('/GetOrgDevices', { orgId, pageToken, pageSize })
      let devices = data.devices
      pageToken = data.pageNextToken
      // just get all devices right now
      while (pageToken) {
        const additional = await rpmClient.post('/GetOrgDevices', { orgId, pageToken, pageSize })
        devices = [...devices, ...additional.data.devices]
        pageToken = additional.data.pageNextToken
      }
      return devices
    }
  } catch (e) {
    console.warn(e)
    throw new Error('User does not belong to this organization.')
  }
}
