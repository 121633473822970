import Vue from 'vue'
import App from './App.vue'
import VueCompositionApi from '@vue/composition-api'
import vuetify from './plugins/vuetify'
import VueTheMask from 'vue-the-mask'
import { onAppReady } from './shared-ui/hooks'
import PortalVue from 'portal-vue'

import router from '@/router'

Vue.use(VueTheMask)
Vue.use(VueCompositionApi)
Vue.use(PortalVue)

Vue.config.productionTip = false

onAppReady(async () => {
  //console.log('main::onAppReady:', JSON.stringify(authUser))
  new Vue({
    vuetify,
    render: h => h(App),
    router,
    mounted() {
      // document.getElementsByClassName('busy-spinner')[0].remove()
    },
  }).$mount('#app')
})
