import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { reactive, readonly } from '@vue/composition-api'
import { rpmClient } from './clients/rpm'
import { onAuthChanged } from '../hooks'
import { getOrgId } from './sso'
import { patientsClient } from './clients/patients'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { collection, setDoc, doc, query, where, onSnapshot, orderBy, deleteDoc } from 'firebase/firestore'

Vue.use(VueCompositionAPI)

const firebaseConfig = {
  apiKey: 'AIzaSyD8RAiis0W0qO55eHZvsXR1rrylJuqa58Y',
  authDomain: 'onechart-monitor-prod.firebaseapp.com',
  databaseURL: 'https://onechart-monitor-prod.firebaseio.com',
  projectId: 'onechart-monitor-prod',
  storageBucket: 'onechart-monitor-prod.appspot.com',
  messagingSenderId: '258556006130',
  appId: '1:258556006130:web:ba082f7b188b9c379b4a6b',
  measurementId: 'G-ZJLM3BLGTG',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

onAuthChanged(async authUser => {
  if (!authUser) {
    _state.patient = null
    _state.latestTasks = []
    _state.latestData = []
    _state.refreshingData = false
    _state.providerReviewNotifications = []
    _state.patientDict = {}
  }
})

const _state = reactive({
  latestTasks: [],
  latestData: [],
  refreshingData: false,
  providerReviewNotifications: [],
  patientDict: {},
})
export const state = readonly(_state)

export const getLatestAlerts = async () => {
  _state.refreshingData = true
  let orgId = await getOrgId()
  const { data } = await rpmClient.post('/GetPatients', { orgId, behavior: 'LatestAlerts' })
  _state.latestAlerts = data.patients
  _state.refreshingData = false
  return data.patients
}

export const getPatientDict = async () => {
  console.log('getting patientDict')
  let orgId = await getOrgId()
  _state.refreshingData = true
  const { data } = await patientsClient.post('/GetOrgPatients', { orgId })
  let dataDict = {}
  data.patients.forEach(patient => {
    dataDict[patient.id] = patient
  })
  _state.patientDict = dataDict
  _state.refreshingData = false
  return data.patients
}

export const getLatestData = async () => {
  let orgId = await getOrgId()
  _state.refreshingData = true
  const { data } = await rpmClient.post('/GetPatients', { orgId, behavior: 'LatestData' })

  _state.latestData = data.patients
  _state.refreshingData = false
  return data.patients
}

export const listenForProviderReview = async () => {
  let orgID = await getOrgId()

  const q = query(collection(db, 'review'), where('org', '==', orgID), orderBy('notificationTimestamp', 'desc'))
  onSnapshot(q, querySnapshot => {
    // set the items in providerReview
    getLatestData()
    let notifications = []
    querySnapshot.forEach(doc => {
      let notification = doc.data()
      if (notification.providerReview) {
        notification.id = doc.id
        notifications.push(notification)
      }
    })
    _state.providerReviewNotifications = notifications
  })
  return _state.providerReviewNotifications
}

export const dismissReviewNotification = async notificationID => {
  await deleteDoc(doc(db, 'review', notificationID))
  return
}

export const lastBridgeAccess = async notification => {
  await setDoc(doc(db, 'lastBridgeAccess/' + notification.patient), notification)
  return
}

export const addReviewNotification = async notification => {
  await setDoc(doc(db, 'review', notification.patient + '' + notification.ts), notification)
  return
}
