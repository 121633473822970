import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import { isDarkMode } from '../shared-ui/store/url'

Vue.use(Vuetify)

//  Theme example
const vuetify = new Vuetify({
  theme: {
    dark: isDarkMode,
    customProperties: true,
    themes: {
      light: {
        appbar: colors.grey.lighten3,
        primary: colors.blue,
        secondary: colors.blue,
        tertiary: '#f5f5f5',
        alert: colors.orange,
        button: colors.green,
        label: colors.grey,
        background: colors.shades.white,
        logo: colors.blue.darken1,
        notificationCard: '#313648',
        acknowledgedAlert: colors.grey.lighten2,
        newAlert: colors.yellow,
      },
      dark: {
        appbar: '#1f222d',
        primary: colors.blue.lighten1,
        secondary: colors.grey.lighten3,
        tertiary: '#313648',
        alert: colors.orange,
        button: colors.green,
        label: colors.white,
        logo: colors.white,
        notificationCard: colors.shades.white,
        acknowledgedAlert: '#3C3C3C',
        newAlert: colors.yellow,
      },
    },
  },
})

vuetify.getTheme = () => {
  const mode = vuetify.framework.theme.dark ? 'dark' : 'light'
  return vuetify.framework.theme.defaults[mode]
}

document.body.style.backgroundColor = vuetify.getTheme().tertiary

export default vuetify
