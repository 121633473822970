<template>
  <v-app-bar :extended="shouldExtend" :extension-height="shouldExtend ? 50 : 0" height="110" color="white" flat app>
    <v-card color="transparent" tile class="flex" flat>
      <v-card height="50" color="transparent" flat tile>
        <v-row align="center" class="fill-height" no-gutters>
          <portal-target name="logoSpace"> </portal-target>
          <v-spacer />
          <portal-target name="toolbarItems"> </portal-target>
        </v-row>
      </v-card>
      <v-card color="transparent" flat height="50">
        <v-row align="center" class="fill-height" style="font-weight: 900; font-size: 25px; color: #4A4A4A" no-gutters>
          <portal-target name="pageTitle"> </portal-target>

          <v-spacer />
          <portal-target name="tagSection"> </portal-target>
        </v-row>
      </v-card>
    </v-card>
    <portal-target @change="handleUpdate" slim slot="extension" name="toolbarTabs"> </portal-target>
  </v-app-bar>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { router } from '@/router/index.js'
import { getPatient } from '@/shared-ui/store/rpm'

export default defineComponent({
  components: {},
  props: {
    allowClearPatient: {
      type: Boolean,
      default: true,
    },
    currentNovoPatient: {
      type: Object,
      default: null,
    },
    currentEhrPatient: {
      type: Object,
      default: null,
    },
    patient: {
      type: Object,
      default: null,
    },
    initialColor: {
      type: String,
      default: 'tertiary',
    },
    title: {
      type: String,
      default: 'Title',
    },
  },
  setup(props) {
    const shouldExtend = ref(false)
    const handleUpdate = newContent => {
      if (newContent) {
        shouldExtend.value = true
      } else {
        shouldExtend.value = false
      }
    }
    const page = ref('taskboard')
    const setEnrolledPatient = async () => {
      if (router.history.current.params.id) {
        let pt = await getPatient(router.history.current.params.id)
        if (pt) {
          page.value = 'chart'
        } else {
          page.value = 'enrollment'
        }
      }
    }

    const mode = computed(() => {
      let modeDetails = {}

      switch (page.value) {
        case 'chart':
          modeDetails.truncate = true
          modeDetails.dark = false
          modeDetails.extension = true
          modeDetails.extensionHeight = 50
          modeDetails.color = 'white'
          break
        case 'taskboard':
          modeDetails.dark = false
          modeDetails.extension = false
          modeDetails.extensionHeight = 0
          modeDetails.color = 'white'
          break
        case 'enrollment':
          modeDetails.extension = false
          modeDetails.extensionHeight = 0
          modeDetails.color = 'white'
          break
        default:
          modeDetails.title = ''
          modeDetails.color = 'white'
          modeDetails.dark = false
          break
      }
      return modeDetails
    })

    const ehrPatient = ref(null)
    const showBackButton = ref(false)
    onMounted(() => {
      page.value = 'taskboard'
      // bridge.getPatient().then(v => {
      //   ehrPatient.value = v
      //   if (ehrPatient.value) {
      //     setEnrolledPatient()
      //   } else {
      //     page.value = 'taskboard'
      //   }
      // })
      // bridge.onPatientChanged(v => {
      //   ehrPatient.value = v
      //   if (ehrPatient.value) {
      //     setEnrolledPatient()
      //   } else {
      //     page.value = 'taskboard'
      //   }
      // })
    })

    const route = computed(() => {
      return router.history.current.name
    })

    watch(
      () => route,
      () => {
        console.log('params updated')
        if (!ehrPatient) {
          // show the back button?
          showBackButton.value = true
        } else {
          showBackButton.value = false
        }
      }
    )
    watch(
      () => props.currentEhrPatient,
      () => {
        ehrPatient.value = props.currentEhrPatient

        if (ehrPatient.value) {
          setEnrolledPatient()
        } else {
          page.value = 'taskboard'
        }
      }
    )

    return { mode, showBackButton, handleUpdate, shouldExtend }
  },
})
</script>
<style scoped>
.titleText {
  transition: all 0.3s;
}
</style>
