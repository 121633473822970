<template>
  <v-dialog v-model="show" max-width="400">
    <v-card class="pb-4">
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>
          Export Facesheet
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-3 px-4">
        The patient facesheet contains basic patient demographics and lists the most recent measurements in the
        patient's chart. The facesheet can be generated for the previous 1, 3, 6, or 12 months. Selecting a range below
        and choosing download will generate a PDF.
      </v-card-text>
      <v-card-text>
        <v-select v-model="numberOfMonths" hide-details outlined dense flat :items="options"> </v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Cancel</v-btn>
        <v-btn color="primary" depressed @click="download()">Download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import { exportFacesheet } from '../shared-ui/helpers/pdf'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const numberOfMonths = ref(1)
    const show = ref(false)
    const options = [
      { text: '1 month', value: 1 },
      { text: '3 months', value: 3 },
      { text: '6 months', value: 6 },
      { text: '12 months', value: 12 },
    ]

    const download = () => {
      let start = dayjs().subtract(numberOfMonths.value, 'months')
      exportFacesheet(props.patient, start)
    }

    const open = () => {
      show.value = true
    }

    return {
      download,
      numberOfMonths,
      options,
      open,
      show,
    }
  },
})
</script>
