import { checkLastActivity, updateLastActivity } from '../helpers/activity'
import { getAccessToken, getRefreshToken, getUser, navigateToSSO, parseJWT, setUser } from '../store/sso'
import { isStandalone } from '../store/url'
import { broadcastAuthChanged, onAuthChanged, onLogoutRequest } from './auth'

/**
 * This is the setup apps should perform on initialization conveniently
 * packaged up in this method. If things change we can simply update
 * this method and all apps will be able to update properly. Requires
 * composition-api for Vue
 *
 * @param {function} handler
 * @param {{ skipLastActivityCheck: boolean, skipNavToSSO: boolean }} opts
 */
export const onAppReady = async (handler, opts = { skipLastActivityCheck: false, skipNavToSSO: false }) => {
  opts.skipLastActivityCheck = opts.skipLastActivityCheck || !isStandalone

  onAuthChanged(user => {
    // if there is no user
    if (!user) {
      // if any errors occur, clear all cookies
      // and navigate back to the SSO login screen
      if (!opts.skipNavToSSO) {
        setTimeout(navigateToSSO)
      }
    }
  })

  onLogoutRequest(navigateToSSO)

  const refreshToken = await getRefreshToken()
  if (refreshToken) {
    updateLastActivity()
  }

  // listen for unload, update last activity before leaving application
  window.addEventListener('beforeunload', updateLastActivity, { capture: true })
  // check last activity if not skipping
  if (!opts.skipLastActivityCheck) {
    // if the last activity is not valid, clear all cookies
    // and navigate back to the SSO login screen
    const isValid = await checkLastActivity()
    if (!isValid) {
      // broadcast the auth has changed (no auth user)
      broadcastAuthChanged(null)
      // navigate back to sso
      return
    }
  }

  let userProfile
  try {
    // if app has a valid refresh token, app should
    // be able to get an access token
    const accessToken = await getAccessToken()
    console.log('onAppReady::accessToken', accessToken)
    // get the User ID from the JWT token
    const { sub } = parseJWT(accessToken)
    // get the user profile for user (no auth required)
    userProfile = await getUser({
      userId: sub,
    })
    // assign as authenticated user
    setUser(userProfile)
    // broadcast auth change with user profile
    broadcastAuthChanged(userProfile)

    if (handler) {
      handler(userProfile)
    }
  } catch (e) {
    console.log('catching')
    broadcastAuthChanged(null)
    navigateToSSO()
  }

  // if all was successful, then exec callback

  return userProfile
}
